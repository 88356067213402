import bgServices from './bgServices.jpg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faLocationArrow, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import LoadingSpinner from './components/LoadingSpinner';
import axios from 'axios';
import L from 'leaflet';

function App() {
  const [errorValue, setErrorValue] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false);
  const [infos, setInfos] = useState(null);

  const ipSearchRef = React.useRef();
  const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  function handleLocalize(e) {
    e.preventDefault();

    let ipSearched = ipSearchRef.current.value.trim();

    if (!ipSearched.match(ipformat)) {
      setErrorValue(true);
    } else {
      setIsLoaded(true);      
      setErrorValue(false);
      
      axios.get('https://geolocation-db.com/jsonp/' + ipSearched)
          .then(result => {                        
            setIsLoaded(false);

            let output = result.data.replace('callback(', '').replace(')', '');
            let infos = JSON.parse(output);            
            setInfos(infos);
            
            let map = L.map('map');            
            map.setView([infos.latitude, infos.longitude], 8);
            
            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="https://www.bennaneweb.fr" target="_blank">Bennane Web</a>'
            }).addTo(map);

            L.marker([infos.latitude, infos.longitude]).addTo(map)
            .bindPopup("<b>IP</b> : " + infos.IPv4 + "<br/>" 
                      + "<b>Ville</b> : " + infos.city + "<br/>" 
                      + "<b>Pays</b> : " + infos.country_name + "(" + infos.country_code + ")<br/>" 
                      + "<b>CP</b> : " + infos.postal+"<br/>"
                      + "<b>Région</b> : " + infos.state)
            .openPopup();            
          })
          .catch(error => {        
            setIsLoaded(false);
          });
    }
  }

  function refreshLocalization(e) {
    e.preventDefault();

    setInfos(null);
  }

  return (
    <div className="App" >
      <header className="App-header" style={{background: `url(${bgServices})`, backgroundSize: `100% 100%` }}>
        <div className="logo">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <span style={{marginLeft: `0.2em` }}>
            Localiser une IP
          </span>          
        </div>
        
        <p>
          Obtenez des informations d'identification de votre adresse IP publique.
        </p>

        <div className="row p-5">
          <div className="input-group">
            {!infos &&
              <input type="text" ref={ipSearchRef} className={"form-control " + (errorValue ? 'inputTextError' : 'inputTextOk') } 
                placeholder="Saisir une adresse IP" aria-label="Adresse IP" aria-describedby="basic-addon2" />
            }
            <div className="input-group-append">
              { 
                infos ? 
                <button className="btn btn-outline-secondary" type="button" onClick={refreshLocalization}>                                
                  <FontAwesomeIcon icon={faTrash} /> Réinitialiser            
                </button> :
                <button className="btn btn-outline-secondary" type="button" onClick={handleLocalize}>                                
                  { 
                    isLoaded ? 
                    <LoadingSpinner /> : 
                    <FontAwesomeIcon icon={faLocationArrow} />
                  }              
                </button>
              }              
            </div>       
          </div>
        </div> 

        <div className="row">
          <div className="panel panel-default">
            <div className="panel-heading">    
              {
                infos && 
                <div className="row p-3 mb-5" id="map"></div>
              }
            </div>
          </div>
        </div>
        
      </header>
    </div>
  );
}

export default App;
