import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import '../App.css';

const LoadingSpinner = () => (
    <div>
        <FontAwesomeIcon className="App-logo" icon={faSpinner} />
    </div>
);

export default LoadingSpinner;